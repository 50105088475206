/** @module accordion */
export default function init() {
  const accordionItem = document.querySelectorAll('.js-accordion-item');

  accordionItem.forEach((item) => {
    item.addEventListener('click', (e) => {
      const btnDown = item.querySelector('.js-accordion-btn-down');

      item.classList.toggle('active');

      if (item.classList.contains('active')) {
        btnDown.style.transform = 'rotate(180deg)';
      }

      if (!item.classList.contains('active')) {
        btnDown.style.transform = 'rotate(0deg)';
      }
    });
  });

}

