/* eslint-disable no-undef */
import inView from '@modules/inView';

/**
 * Open lightbox of images when main gallery image button is clicked
 */
export default function init() {
  const galleries = [...document.querySelectorAll('.js-lightbox')];

  inView(galleries, () => {
    Promise.all([
      import('lightgallery.js'),
      import('lightgallery.js/dist/css/lightgallery.css'),
    ]).then(([{ default: lightGallery }]) => {
      galleries.forEach((gallery) => {
        const wrappers = [...gallery.querySelectorAll('.lightbox-trigger-wrapper')];
        if (wrappers.length) {
          // Make array of images for lightbox
          const imageArr = [];
          wrappers.forEach((wrapper) => {
            const trigger = wrapper.querySelector('.js-lightbox-trigger');
            imageArr.push({
              src: trigger.dataset.lightboxImage,
              subHtml: trigger.dataset.lightboxCaption,
            });
            trigger.addEventListener('click', (event) => {
              // Get the current selected flickity slide
              const selected = wrappers.findIndex((slide) => slide.classList.contains('is-selected'));
              // Create the lightbox dynamically
              // https://sachinchoolur.github.io/lightgallery.js/docs/api.html#dynamic
              lightGallery(event.target, {
                dynamic: true,
                dynamicEl: imageArr,
                download: false,
                index: selected,
              });
            });
          });
        }
      });
    });
  });
}
