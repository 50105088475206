export default function init() {
  document.querySelectorAll('.c-tabbed-content__tabs__item').forEach(tab => {
    tab.addEventListener('click', () => {
      const tabId = tab.getAttribute('data-tab');

      // Remove current classes from all tabs and contents
      document.querySelectorAll('.c-tabbed-content__tabs__item').forEach(item => {
        item.classList.remove('c-tabbed-content__tabs__item--current');
      });

      document.querySelectorAll('.c-tabbed-content__contents__content').forEach(content => {
        content.classList.remove('c-tabbed-content__contents__content--current', 'c-tabbed-content__contents__content--visible-mobile', 'c-tabbed-content__contents__content--visible-desktop');
      });

      // Add current class to clicked tab
      tab.classList.add('c-tabbed-content__tabs__item--current');
      const content = document.getElementById(tabId);
      content.classList.add('c-tabbed-content__contents__content--current');

      // Handle mobile and desktop views
      if (window.innerWidth <= 768) {
        content.classList.add('c-tabbed-content__contents__content--visible-mobile');
        tab.insertAdjacentElement('afterend', content); // Move the content directly after the clicked tab
      } else {
        content.classList.add('c-tabbed-content__contents__content--visible-desktop');
        document.querySelector('.c-tabbed-content__contents').appendChild(content); // Move the content directly after all tabs
      }
    });
  });
}
