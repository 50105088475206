/* eslint-disable no-param-reassign */
export default function init() {
// Wait until the DOM is ready
  document.addEventListener('DOMContentLoaded', () => {
    // Fetch the form we want to deal with
    const forms = document.querySelectorAll('.js-form');
    if (forms) {
      forms.forEach(($form) => {
        // Find the CSRF token hidden input, so we can replace it
        const $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
        const formHandle = $form.querySelector('input[name="handle"]');
        // Fetch the new tokens for the form and replace the captcha inputs
        fetch(`/actions/formie/forms/refresh-tokens?form=${formHandle.value}`)
          .then((result) => result.json())
          .then((result) => {
            // Update the CSRF token and captchas.
            $csrfInput.value = result.csrf.token;
            // Use `csrf.param` and `csrf.token`
            $form.querySelector(`input[name="${result.csrf.param}"]`).value = result.csrf.token;
            // Find the JavaScript captcha hidden input, so we can update it
            if (result.captchas && result.captchas.javascript) {
              // JavaScript captcha
              const jsCaptcha = result.captchas.javascript;
              $form.querySelector(`input[name="${jsCaptcha.sessionKey}"]`).value = jsCaptcha.value;
            }
            // Find the Duplicate captcha hidden input, so we can update it
            if (result.captchas && result.captchas.duplicate) {
              // Duplicate captcha
              const duplicateCaptcha = result.captchas.duplicate;
              $form.querySelector(`input[name="${duplicateCaptcha.sessionKey}"]`).value = duplicateCaptcha.value;
            }
            // Update the form's hash (if using Formie's themed JS)
            if ($form.form && $form.form.formTheme) {
              $form.form.formTheme.updateFormHash();
            }
          });
      });
    }
  });
}
