/** @module carousel */
/* eslint-disable func-names, no-underscore-dangle */
import inView from '@modules/inView';

const getFlickity = () => import('flickity');
const getFlickityImagesLoaded = () => import('flickity-imagesloaded');
const getFlickityStyles = () => import('flickity/dist/flickity.css');

const carousels = [];

function changeSlideClasses(slide, method, className) {
  if (!slide) {
    return;
  }
  slide.getCellElements().forEach((cellElem) => {
    cellElem.classList[method](className);
  });
}

function sliderSetUp(sliders) {
  Promise.all([
    getFlickity(),
    getFlickityStyles(),
    getFlickityImagesLoaded(),
  ]).then(([module]) => {
    const Flickity = module.default;
    // Add .is-previous, .is-next classes for styling purposes
    Flickity.createMethods.push('_createPrevNextCells');

    Flickity.prototype._createPrevNextCells = function () {
      this.on('select', this.setPrevNextCells);
    };

    Flickity.prototype.setPrevNextCells = function () {
      // remove classes
      changeSlideClasses(this.previousSlide, 'remove', 'is-previous');
      changeSlideClasses(this.nextSlide, 'remove', 'is-next');
      // set slides
      this.previousSlide = this.slides[this.selectedIndex - 1];
      this.nextSlide = this.slides[this.selectedIndex + 1];
      // add classes
      changeSlideClasses(this.previousSlide, 'add', 'is-previous');
      changeSlideClasses(this.nextSlide, 'add', 'is-next');
    };

    sliders.forEach((slider) => {
      let carousel;
      // Carousel with lightbox
      if (slider.classList.contains('js-lightbox')) {
        carousel = new Flickity(slider, {
          contain: true,
          imagesLoaded: true,
          lazyLoad: 2,
          draggable: false,
          arrowShape: 'M10,49.25l9.71-8-.83-1L7.3,50,18.9,59.6l.84-1L10,50.6H92.89V49.25Z',
        });
        // Homepage carousel
      } else if (slider.classList.contains('c-hero-carousel')) {
        carousel = new Flickity(slider, {
          on: {
            ready() {
              [...slider.querySelectorAll('.slide-hidden')].forEach((item) => {
                item.classList.remove('slide-hidden');
              });
            },
          },
          contain: true,
          imagesLoaded: true,
          autoPlay: 4000,
          lazyLoad: 1,
          draggable: true,
          wrapAround: true,
          arrowShape: 'M10,49.25l9.71-8-.83-1L7.3,50,18.9,59.6l.84-1L10,50.6H92.89V49.25Z',
        });
        [...document.querySelectorAll('.c-home-banner-cta__arrow-container')].forEach((item) => {
          item.addEventListener('click', () => {
            carousel.next();
          });
        });
        // eslint-disable-next-line max-len
      } else if (slider.classList.contains('l-content-hub-additional-articles__grid')) {
        carousel = new Flickity(slider, {
          imagesLoaded: true,
          lazyLoad: 2,
          wrapAround: true,
          watchCSS: true,
          initialIndex: 3,
          arrowShape: 'M10,49.25l9.71-8-.83-1L7.3,50,18.9,59.6l.84-1L10,50.6H92.89V49.25Z',
        });
      } else {
        // Logo carousel
        carousel = new Flickity(slider, {
          imagesLoaded: true,
          lazyLoad: 2,
          wrapAround: true,
          initialIndex: 3,
          arrowShape: 'M10,49.25l9.71-8-.83-1L7.3,50,18.9,59.6l.84-1L10,50.6H92.89V49.25Z',
        });
      }
      carousels.push(carousel);
    });
  });
}

/*
* Create carousels from all elements with '.js-carousel'
*/
export default function init() {
  const sliders = [...document.querySelectorAll('.js-carousel')];
  inView(sliders, () => sliderSetUp(sliders));
}
